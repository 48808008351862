/* You can add global styles to this file, and also import other style files */

@import "./assets/fonts/Golos-UI";

@include fontGolosUI;

html,
body {
  font-size: 10px;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "GolosUI";
}
