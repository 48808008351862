@mixin fontGolosUI {
  /* Настройка шрифтов для старых браузеров */
  @font-face {
    font-family: "GolosUI";
    src: url("~/assets/fonts/Golos-UI/golos-ui_regular.ttf") format("truetype"), url("~/assets/fonts/Golos-UI/golos-ui_regular.woff2") format("woff2"),
      url("~/assets/fonts/Golos-UI/golos-ui_regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "GolosUI";
    src: url("~/assets/fonts/Golos-UI/golos-ui_medium.ttf") format("truetype"), url("~/assets/fonts/Golos-UI/golos-ui_medium.woff2") format("woff2"),
      url("~/assets/fonts/Golos-UI/golos-ui_medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "GolosUI";
    src: url("~/assets/fonts/Golos-UI/golos-ui_bold.ttf") format("truetype"), url("~/assets/fonts/Golos-UI/golos-ui_bold.woff2") format("woff2"),
      url("~/assets/fonts/Golos-UI/golos-ui_bold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
  }

  /*
        Настройка шрифтов для современных браузеров

        Подробнее здесь: https://web.dev/i18n/ru/variable-fonts/
     */
  @supports (font-variation-settings: normal) {
    @font-face {
      font-family: "GolosUI";
      src: url("~/assets/fonts/Golos-UI/golos-ui_vf.woff2") format("woff2 supports variations"),
        url("~/assets/fonts/Golos-UI/golos-ui_vf.woff2") format("woff2-variations");
      font-weight: 100 1000;
    }
  }
}
